import packageJson from '../../package.json';

export const environment = {
    production: true,
    version: packageJson.version,
    federation: {
        manifest: '/assets/federation.manifest.json',
    },
    configPath: 'config-prod.json',
    imports: [],
};
